<template>
  <div class="condition"
    v-loading="loading"
    element-loading-text="列表加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(1,34,48, 0)"
    >
    <span class="SelectText">业务单元：</span>
    <el-select v-model="value" @change="selectedLocations" :style="{width:width}"
              placeholder="请选择">
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.BusinessName"
        :value="item.BusinessId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {isEmpty} from '@/common/js/base.js'
import { GetBusinessUnitList } from "@/api/EquipmentOperationMonitoring"; //页面接口

export default {
  props: {
    locationId:{
      type: [String,Number],
      default: ''
    },
    isContainAll:{ 
      type:Boolean,
      default: () => { return true } 
    },
  },
  data () {
    return {
      value:'',
      width:'10vw',
      list:[],
      loading:false
    }
  },
  watch: {//关联查询

  },
  mounted () {
    this.$nextTick(() => {
      this.getLocations()
    })
  },
  beforeDestroy () {
  },
  methods: {
    selectedLocations(e){
      this.$emit("selectedLocations",e);
    },
    getLocations(){
        this.list = [];
        this.loading = true;
		GetBusinessUnitList({}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading = false;
		   this.value = '';
		   this.$notify.info({
		     title:'消息',
		     message: '获取业务单元失败'
		   });
		   return;
		 }else if(obj.length == 0) {
		   this.loading = false;
		   this.value = '';
		   this.$notify.info({
		     title: '消息',
		     message:'业务单元数据为空'
		   });
		   return;
		 }
		 // list.push(list.shift());//第一个元素放在后面
		 this.list = obj;
		 this.value = obj[0].BusinessId;
		 this.loading = false;
		 this.$emit("selectedLocations",this.value);
		}).catch(err => {
			this.loading = false;
			this.value = '';
		})
    }
  }
}
</script>

<style scoped>
.condition{
  display: inline-block;
}
.SelectText{
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
</style>
