<template>
  <div class="condition"
    v-loading="loading"
    element-loading-text="列表加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(1,34,48, 0)"
    >
    <span class="SelectText">厂房:</span>
    <el-select v-model="value" @change="selectedLocations" :style="{width:width}"
              placeholder="请选择">
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.LocationName"
        :value="item.LocationId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {isEmpty} from '@/common/js/base.js'
import { GetLocationsList } from "@/api/EquipmentOperationMonitoring"; //页面接口

export default {
  props: {
    locationId:{
      type: [String,Number],
      default: ''
    },
  },
  data () {
    return {
      value:'',
      width:'10vw',
      list:[],
      loading:false,
	  Name:'',
	  Level:''
    }
  },
  watch: {//关联查询

  },
  mounted () {
    this.$nextTick(() => {
      this.getLocations()
    })
  },
  beforeDestroy () {
  },
  methods: {
    selectedLocations(e){
	  let result = this.list.filter(item=>item.LocationId === e);
	  this.Name = result[0].LocationName;
	  this.Level = result[0].LocationLevel;
      this.$emit("selectedLocations",e,this.Name,this.Level);
    },
    getLocations(){
        this.list = [];
		this.Name = '';
		this.Level = '';
        this.loading = true;
        GetLocationsList({
        	AgencyId:sessionStorage.getItem('agencyId'),
        }).then(res => {
        let obj = res.Content;
         if(res.Code==-1) {
           this.loading = false;
           this.value = '';
           this.$notify.info({
             title:'消息',
             message: '获取厂房失败'
           });
           return;
         }else if(obj.length == 0) {
           this.loading = false;
           this.value = '';
           this.$notify.info({
             title: '消息',
             message:'厂房数据为空'
           });
           return;
         }
         // list.push(list.shift());//第一个元素放在后面
         this.list = obj;
		 if(this.locationId){
			this.value = this.locationId/1;
			let result = this.list.filter(item=>item.LocationId === this.value);
			this.Name = result[0].LocationName;
			this.Level = result[0].LocationLevel;
		 }else{
			this.value = obj[0].LocationId;
			let result = this.list.filter(item=>item.LocationId === this.value);
			this.Name = result[0].LocationName;
			this.Level = result[0].LocationLevel;
		 }
         
         this.loading = false;
         this.$emit("selectedLocations",this.value,this.Name,this.Level);
        }).catch(err => {
        	this.loading = false;
        	this.value = '';
        })
    }
  }
}
</script>

<style scoped>
.condition{
  display: inline-block;
}
.SelectText{
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
</style>
